export enum ModalTypes {
  ActivateCustomerModal,
  ActivateUserModal,
  AddCanvasModal,
  AddScheduleModal,
  ArchiveCustomerModal,
  DeleteCustomerModal,
  ArchiveUserModal,
  AssetAuditLogModal,
  AssetUploadModal,
  DeleteAssetModal,
  DeleteCanvasModal,
  DeletePlaylistAssetModal,
  DeletePlaylistModal,
  DeleteSchedulModal,
  DeleteUserModal,
  HeaderModal,
  LogoutModal,
  SchedulePlaylistModal,
  UpdateCanvasModal,
  UpdateCustomerModal,
  UpdateScheduleModal,
  UpdateUserModal,
}
