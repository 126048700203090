import { UilEllipsisV } from "@iconscout/react-unicons";
import { useState } from "react";
import { Edit, Activity, Archive, Trash2 } from "react-feather";
import { useRecoilState } from "recoil";
import { ModalTypeState, SelectedCustomerState } from "../../atoms";
import { CustomerListDto, CustomerStatus } from "../../services";
import { ModalTypes } from "../modal";
import { Store } from "../../helpers";
import { RequireAdminRole } from "../auth";

type Props = {
  customer: CustomerListDto;
};

export function CustomerActionButton({ customer }: Props) {
  const loggedInUser = Store.getLoggedInUser();
  const [selectedCustomer, setSelectedCustomer] =
    useRecoilState<CustomerListDto | null>(SelectedCustomerState);
  const [modalType, setModalType] = useRecoilState(ModalTypeState);
  const [showOption, setShowOption] = useState(false);

  const selectCustomer = () => {
    if (showOption) {
      setSelectedCustomer(null);
      setShowOption(false);
    } else {
      setSelectedCustomer(customer);
      setShowOption(true);
    }
  };

  const handleArchiveClick = () => {
    setModalType(ModalTypes.ArchiveCustomerModal);
  };

  const handleDeleteClick = () => {
    setModalType(ModalTypes.DeleteCustomerModal);
  };

  const handleActivateClick = () => {
    setModalType(ModalTypes.ActivateCustomerModal);
  };

  const handleEditClick = () => {
    setModalType(ModalTypes.UpdateCustomerModal);
  };

  const handleOnBlur = () => {
    setShowOption(false);
  };

  return (
    <div className="relative" tabIndex={1} onBlur={handleOnBlur}>
      <UilEllipsisV className="cursor-pointer" onClick={selectCustomer} />
      {selectedCustomer?.id === customer.id &&
        showOption &&
        (modalType === undefined || modalType === null) && (
          <div
            className={`absolute top-1 z-10 right-4 rounded-md border border-page-border bg-dropdown min-w-max cursor-pointer`}
          >
            <ul className=" max-h-64 rounded-md overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
              <li>
                <label
                  className="flex items-center rounded-md gap-2 p-2.5 cursor-pointer hover:bg-button-bg-primary-hover"
                  onClick={handleEditClick}
                >
                  <Edit className="w-4 first-letter:text-gray-200" />
                  <span>Update</span>
                </label>
              </li>
              {customer.status === CustomerStatus.Active && (
                <li>
                  <label
                    className="flex items-center rounded-md gap-2 p-2.5 cursor-pointer hover:bg-button-bg-primary-hover"
                    onClick={handleArchiveClick}
                  >
                    <Archive className="w-4 text-gray-200" />
                    <span>Archive</span>
                  </label>
                </li>
              )}
              {customer.status === CustomerStatus.Archived && (
                <li>
                  <label
                    className="flex items-center rounded-md gap-2 p-2.5 cursor-pointer hover:bg-button-bg-primary-hover"
                    onClick={handleActivateClick}
                  >
                    <Activity className="w-4 text-gray-200" />
                    <span>Activate</span>
                  </label>
                </li>
              )}
              <RequireAdminRole user={loggedInUser}>
                <li>
                  <label
                    className="flex items-center rounded-md gap-2 p-2.5 text-button-bg-destructive-default cursor-pointer hover:bg-button-bg-primary-hover"
                    onClick={handleDeleteClick}
                  >
                    <Trash2 className="w-4" />
                    <span>Delete</span>
                  </label>
                </li>
              </RequireAdminRole>
            </ul>
          </div>
        )}
    </div>
  );
}
