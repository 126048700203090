import { useRecoilState } from "recoil";
import { ModalTypeState, SelectedCustomerState } from "../../atoms";
import { BaseModal } from "./BaseModal";
import { CustomersClient } from "../../services";
import { GetNewInstance } from "../../helpers/HttpCommon";
import { ModalTypes } from "./ModalTypes";
import { toast } from "react-toastify";

type Props = {
  onSuccess: () => void;
};

export function DeleteCustomerModal({ onSuccess }: Props) {
  const [, setModalType] = useRecoilState(ModalTypeState);
  const [selectedCustomer, setSelectedCustomer] = useRecoilState(
    SelectedCustomerState
  );

  const client = new CustomersClient(undefined, GetNewInstance());

  const onCancel = () => {
    setSelectedCustomer(null);
    setModalType(null);
  };

  const onDeleteClick = () => {
    selectedCustomer?.id &&
      client
        .deleteCustomer(selectedCustomer?.id)
        .then((x) => {
          toast.success("Customer deleted successfully");
          onSuccess();
          setSelectedCustomer(null);
          setModalType(null);
        })
        .catch((error) => {
          setSelectedCustomer(null);
          setModalType(null);
        });
  };

  return (
    <BaseModal
      id={ModalTypes.DeleteCustomerModal}
      title="Delete customer"
      description={
        "Are you sure you want to delete " + selectedCustomer?.name + "?"
      }
    >
      <div className="py-4 text-sm text-white">
        <div className="mt-8 flex justify-end gap-4">
          <button
            onClick={onCancel}
            className="py-2.5 px-4 rounded-md border border-page-border bg-white bg-opacity-5 hover:bg-opacity-10"
          >
            Cancel
          </button>
          <button
            onClick={onDeleteClick}
            className="py-2.5 px-4 rounded-md bg-button-bg-destructive-default hover:bg-button-bg-destructive-hover active:shadow-button-bg-destructive-default"
          >
            Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
}
